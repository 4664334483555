import { ThemeProvider } from '@lemonenergy/lemonpie'
import { Logo } from '@lemonenergy/lemonpie-3'
import { Links, LiveReload, Scripts, ScrollRestoration } from '@remix-run/react'

import useCurrentLocation from '~/hooks/useCurrentLocation'
import { useRouteErrorWithReporter } from '~/hooks/useRouteErrorWithReporter'
import Html from '~/styles/Html'

const defaultMessage =
  'Opa, infelizmente não encontramos o que você estava procurando...'

export const GenericErrorSection = ({
  message = defaultMessage,
}: {
  message?: string
}) => {
  const [url] = useCurrentLocation()

  return (
    <div className="bg-expressive-strong flex-1 flex text-center flex-col gap-larger justify-center items-center w-full fg-inverted-main px-small">
      <div className="w-larger h-larger">
        <Logo variant="symbol" />
      </div>

      <p className="typography-section sm:typography-heading text-center mb-main">
        {message}
      </p>

      <p className="typography-body-small sm:typography-subheading fg-inverted-soft">
        {url}
      </p>
    </div>
  )
}

export default function GenericErrorBoundary() {
  const error = useRouteErrorWithReporter()

  if (process.env.NODE_ENV !== 'production') console.error(error)

  return (
    <ThemeProvider>
      <Html>
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <Links />
          {typeof window === 'undefined' && '__STYLES__'}
        </head>

        <body>
          <GenericErrorSection />
        </body>

        <ScrollRestoration />
        <Scripts />

        {process.env.NODE_ENV === 'development' && <LiveReload />}
      </Html>
    </ThemeProvider>
  )
}
